import { AnalyticsBrowser } from '@segment/analytics-next';

class NoOperationAnalytics {
  track() {
    return Promise.resolve();
  }

  identify() {
    return Promise.resolve();
  }

  page() {
    return Promise.resolve();
  }

  group() {
    return Promise.resolve();
  }

  alias() {
    return Promise.resolve();
  }

  flush() {
    return Promise.resolve();
  }
}

const analytics = process.env['REACT_APP_SEGMENT_WRITE_KEY']
  ? AnalyticsBrowser.load({
      writeKey: process.env['REACT_APP_SEGMENT_WRITE_KEY'] as string,
    })
  : new NoOperationAnalytics();

export default analytics;
