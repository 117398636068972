import { useMutation } from '@tanstack/react-query';

import { api } from '../../../api';
import { UpcomingInvoice } from '../../../types/payments';

type GetUpcomingInvoiceMutationPayload = {
  priceIds: string[];
  promoCode: string | null;
};

export default function useGetUpcomingInvoice(currencyCode: string = 'gbp') {
  return useMutation({
    mutationFn: (payload: GetUpcomingInvoiceMutationPayload) =>
      api
        .post<UpcomingInvoice>(
          `/payments/upcoming-invoice?currencyCode=${currencyCode}`,
          payload
        )
        .then((res) => res.data),
  });
}
