export type SubscriptionPriceTimePeriod =
  | 'monthly'
  | '3 months'
  | '6 months'
  | '12 months';

export type SubscriptionPrice = {
  id: string;
  key: string;
  amount: number;
  currency: string;
  timePeriod?: SubscriptionPriceTimePeriod;
  metadata?: { [name: string]: string };
};

export type PromotionCode = {
  id: string;
  code: string;
  coupon: Coupon;
};

export type Coupon = {
  amount_off: number;
  percent_off: number;
};

export type PaymentPlans = {
  subscriptionsPrices: SubscriptionPrice[];
  geneticAnalysisPackPrices: SubscriptionPrice[];
  bundlePrice: SubscriptionPrice;
};

export type UpcomingInvoice = {
  amount: number;
  currencyCode: string;
};

export const METADATA_SUBSCRIPTIONS = 'app_product_subscription';
export const METADATA_GENETIC_ANALYSIS_PACK =
  'app_product_genetic_analysis_pack';
export const METADATA_DNA_UPLOAD = 'app_product_dna_upload';
export const METADATA_DNA_UPLOAD_FREE = 'app_product_dna_upload_free';
export const METADATA_BUNDLE = 'app_product_bundle';
