import { useQuery } from '@tanstack/react-query';

import { api } from '../../../api';
import { PaymentPlans } from '../../../types/payments';
import { QueryResultData } from '../../../types/queries';
import { queryKeys } from '../../../utils';

export default function useGetPaymentPlans(
  currencyCode: string = 'gbp'
): QueryResultData<PaymentPlans> {
  return useQuery({
    queryKey: queryKeys.payments.getPaymentPlans(),
    queryFn: () =>
      api
        .get<PaymentPlans>(
          `/payments/payment-plans?currencyCode=${currencyCode}`
        )
        .then((res) => res.data),
  });
}
