export function formatMinutesTime(minutes: number): string {
  if (minutes < 60) {
    return `${minutes}m`;
  }

  const hours = Math.floor(minutes / 60);
  const remainingMinutes = minutes % 60;

  return `${hours}h ${remainingMinutes}m`;
}

export function capitalizeFirstLetter(string: string): string {
  if (!string) return '';

  return string.charAt(0).toUpperCase() + string.slice(1);
}

type FormatCurrencyArgs = {
  amount: number;
  languageTag: string;
  currency: string;
};

export const formatCurrency = ({
  amount,
  languageTag = 'en-US',
  currency,
}: FormatCurrencyArgs) => {
  return Intl.NumberFormat(languageTag, {
    style: 'currency',
    currency: currency.toUpperCase(),
  }).format(amount / 100);
};
